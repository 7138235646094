<template>
  <v-col cols="6" xs="12"  sm="12" md="6" lg="6" xl="6">
    <CardDriver :data="dataProp" v-if="dataProp.type == 0" @reloadPage="onReloadPage()" />
    <CardTransportCompany :data="dataProp" v-if="dataProp.type == 1" @reloadPage="onReloadPage()" />
  </v-col>
</template>

<script>
export default {
  name: "CardClient",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CardDriver: () =>
      import(
        /* webpackChunkName: "CardDriver" */ "@/components/views/Trip/Offers/Client/CardDriver/CardDriver.vue"
      ),
    CardTransportCompany: () =>
      import(
        /* webpackChunkName: "CardTransportCompany" */ "@/components/views/Trip/Offers/Client/CardTransportCompany/CardTransportCompany.vue"
      ),
  },
  computed: {
    dataProp: {
      get() {
        return this.data
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onReloadPage() {
      this.$emit("reloadPage");
    },
  },
};
</script>
